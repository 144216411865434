import * as React from 'react'

function SvgArrowDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.986 9.493" {...props}>
      <path
        data-name="Path 156"
        d="M2.121 2.122l5.872 5.872 5.872-5.872"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
    </svg>
  )
}

export default SvgArrowDown
