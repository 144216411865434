import * as React from 'react'

function SvgPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.136 17.136" {...props}>
      <path d="M1.502 8.568h14.136M8.568 15.634V1.498" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth={3} />
    </svg>
  )
}

export default SvgPlus
