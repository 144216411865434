import React, { HTMLAttributes } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  className?: string
  size: 'tiny' | 'small' | 'medium' | 'large' | 'none'
  rotate?: boolean
}

/**
 * An Icon component which renders as a span. Accepts the listed props and all standard HTMLAttributes on a span
 * The icons within the /Icons folder is generated via svgr and the script generate:svgs in package.json
 * add a new one by adding a svg file to assets/icons and run the script
 * usage:
 * import  { Icon, Close } from shared/components/Icon
 * <Icon icon={Close} />
 * @param {React.FC<React.SVGProps<SVGSVGElement>>} props.icon - A svg react-component used in the icon
 */
const Icon: React.FC<Props> = (props: Props) => {
  //eslint-disable-next-line
  const { icon, size, className, rotate, ...htmlAttributes } = props

  const classes = classNames(styles.icon, styles[size], className, {
    [styles.rotate]: rotate,
    [styles.cursor]: htmlAttributes.onClick
  })

  return (
    <span className={classes} {...htmlAttributes}>
      <props.icon />
    </span>
  )
}

export default React.memo(Icon)
