import * as React from 'react'

function SvgShoppingcart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <clipPath id="shoppingcart_svg__a">
          <path data-name="Path 31" d="M0-49.6h27.044v-27.245H0z" transform="translate(0 76.845)" fill="rgba(0,0,0,0)" />
        </clipPath>
      </defs>
      <g data-name="Group 12">
        <g data-name="Group 11" clipPath="url(#shoppingcart_svg__a)">
          <g data-name="Group 8">
            <path
              data-name="Path 28"
              d="M8.627 21.397a2.874 2.874 0 00-2.823 2.922 2.874 2.874 0 002.823 2.922 2.874 2.874 0 002.823-2.922 2.874 2.874 0 00-2.823-2.922m0 4.546a1.6 1.6 0 01-1.568-1.624 1.6 1.6 0 011.568-1.622 1.6 1.6 0 011.569 1.622 1.6 1.6 0 01-1.569 1.624"
              fill="#fff"
            />
          </g>
          <g data-name="Group 9">
            <path
              data-name="Path 29"
              d="M20.548 21.397a2.874 2.874 0 00-2.823 2.922 2.874 2.874 0 002.823 2.922 2.874 2.874 0 002.823-2.922 2.874 2.874 0 00-2.823-2.922m0 4.546a1.6 1.6 0 01-1.568-1.624 1.6 1.6 0 011.568-1.622 1.6 1.6 0 011.569 1.622 1.6 1.6 0 01-1.569 1.624"
              fill="#fff"
            />
          </g>
          <g data-name="Group 10">
            <path
              data-name="Path 30"
              d="M26.917 4.351a.775.775 0 00-.5-.26L5.995 3.799l-.567-1.786A2.9 2.9 0 002.728 0h-2.1a.639.639 0 00-.627.649.639.639 0 00.627.649h2.1a1.638 1.638 0 011.506 1.137l3.985 12.438-.314.747a3.212 3.212 0 00.283 2.823 2.9 2.9 0 002.353 1.364h12.2a.639.639 0 00.627-.649.639.639 0 00-.627-.649h-12.2a1.589 1.589 0 01-1.313-.781 1.84 1.84 0 01-.162-1.555l.251-.584 13.211-1.433a3.493 3.493 0 002.98-2.727l1.506-6.526a.567.567 0 00-.094-.552m-2.635 6.786a2.158 2.158 0 01-1.917 1.721l-13.051 1.4-2.918-9.156 19.231.292z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgShoppingcart
