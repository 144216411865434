import * as React from 'react'

function SvgMinus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.136 3" {...props}>
      <path d="M1.502 1.5h14.136" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth={3} />
    </svg>
  )
}

export default SvgMinus
