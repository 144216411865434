import * as React from 'react'

function SvgVArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 82 130" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M75 63l4.599 5.277a7 7 0 00-.179-10.705L75 63zM9.599 129.277l70-61L70.4 57.723l-70 61L9.6 129.277zM79.42 57.572l-70-57L.58 11.428l70 57 8.84-10.856z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgVArrow
